import * as React from "react";
import "../scss/bulma-theme.scss";

import Layout from "../components/common/layout";
import RevcoreServicesPage from "../components/frontend/services/services"

const ServicesPage = () => {

  
  return (
    <Layout>
      <RevcoreServicesPage/>
    </Layout>
  );
};

export default ServicesPage;
