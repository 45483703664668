import React from "react";

import { navigate, Link } from "gatsby";

const RevcoreServicesPage = () => {
  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <section
      className="has-background-dark"
      style={{ padding_bottom: "140px" }}
    >
      <div className="container has-text-white py-4">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Development Services
            </h2>
            <p className="subtitle has-text-white mb-5">
                We believe in technology, not hype.
            </p>
            <p className="subtitle has-text-white mb-5">
              Led by Michael "Yeastplume" Cordner, long-time core developer on
              the fairly-launched{" "}
              <a href="https://github.com/mimblewimble/grin">
                Grin Cryptocurrency Project
              </a>
              , Revcore is a development services company dedicated to no-nonsense technological advancement in
              security, privacy, and cryptocurrency-related fields. 
            </p>
            <p className="subtitle has-text-white mb-5">
              We're very focused on Grin and Mimblewimble development at the moment, but
              we're always happy to talk about whatever projects you may have in
              mind.
            </p>
            <p className="subtitle has-text-white mb-5">
              <Link to="/contact">Get in touch!</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevcoreServicesPage;
